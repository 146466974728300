.container {
	width: 100% !important;
	max-width: rem(1528px) !important;
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-small {
		max-width: rem(760px) !important;
	}

	&.is-medium {
		max-width: rem(1016px) !important;
	}
}
