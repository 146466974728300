$section-padding: 0;
$section-padding-medium: 0;
$section-padding-large: 0;

$section-background: #e8eaf1;

@import '~bulma/sass/layout/section';

.section {
	position: relative;
	overflow: hidden;
	z-index: 0;
	padding: rem(100px) 0;

	&:nth-of-type(even) {
		background: $primary-background;

		.section-text-block {
			background: #fff;
		}
	}

	&.is-intro {
		padding: 0 0 rem(91px);
		background: url(../../../images/craft-pattern.jpg) $craft-background;
		text-align: center;
		color: $craft-text;

		&:after {
			position: absolute;
			content: '';
			z-index: -1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: url(../../../images/patern.svg);
			background-size: rem(500px) rem(500px);
		}

		&.has-no-breadcrumbs {
			padding: rem(85px) 0 rem(91px);
		}
	}
}

.overview {
	padding: rem(100px) 0;
}

.overview-text {
	max-width: rem(967px);
	margin: rem(-15px) auto rem(31px);
	text-align: center;
}

.section-text-block-container {
	overflow: hidden;

	section:nth-of-type(even) & {
		background: #fff;
		padding: rem(80px) rem(25px) rem(91px);

		@include mobile-m {
			padding-right: rem(40px);
			padding-left: rem(40px);
		}

		@include tablet {
			padding-right: rem(70px);
			padding-left: rem(70px);
		}

		@include widescreen {
			padding-right: rem(100px);
			padding-left: rem(100px);
		}
	}
}

.section-text-block,
.section-form-block-container {
	background: $primary-background;
	padding: rem(80px) rem(25px) rem(91px);

	@include mobile-m {
		padding-right: rem(30px);
		padding-left: rem(40px);
	}

	@include tablet {
		padding-right: rem(60px);
		padding-left: rem(70px);
	}

	@include widescreen {
		padding-right: rem(80px);
		padding-left: rem(100px);
	}
}

.section-text-block {

	&.has-primary-background {
		background: $primary !important;
		color: #fff;
	}

	&.has-margin-top {

		@include desktop {
			margin-top: rem(100px);
		}
	}
}

.section-form-block-container {
	background: $primary url(../../../images/patern.svg);
	background-size: rem(500px) rem(500px);
	color: #fff;
	text-align: center;
	padding-bottom: rem(62px);

	form {
		text-align: left;
	}
}