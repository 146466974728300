﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 25px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.2s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	@include font-title-20;
	opacity: 1;
	visibility: visible;
	z-index: 1001;
	position: fixed;
	height: 56px;
	width: 100%;
	padding: 0 10px;
	border: none;
	left: 0;
	bottom: 0;
	cursor: pointer;
	background: $secondary;
	color: #fff;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	font-weight: 400;

	@include desktop {
		height: 48px;
		width: auto;
		border-radius: $radius;
		padding: 0 30px;
		margin: 0;
		top: 26px;
		left: 48px;
	}

	&.is-active {
		background: #fff;
		color: $secondary;

		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background: $secondary;
		}
	}

	.has-fixd-position & {

		@include desktop {
			right: 56px;
		}
	}

	.hamburger-box {
		top: 2px;
	}

	.hamburger-label {
		padding-left: 12px;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		background: lighten($secondary, 10%);
		color: #fff;

		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background: #fff;
		}
	}
}