﻿.logo {
	position: absolute;
	z-index: 101;
	display: block;
	width: rem(180px);
	height: rem(180px);
	top: rem(100px);
	left: 50%;
	transform: translateX(-50%);
	background: url(../../images/logo-lugt-lisianthus.svg) no-repeat center;
	background-size: contain;

	@include tablet {
		width: rem(230px);
		height: rem(230px);
		top: rem(50px);
	}
}

.logo-five-star {
	position: absolute;
	width: rem(193px);
	height: rem(50px);
	top: rem(25px);
	left: rem(20px);
	background: url(../../images/logo-five-stars.svg) no-repeat center;
	background-size: contain;

	@include desktop {
		top: rem(25px);
		left: rem(230px);
	}
}

.logo-white {
	display: block;
	width: rem(132px);
	height: rem(150px);
	margin: 0 auto;
	background: url(../../images/logo-lugt-lisianthus-white.svg) no-repeat center;
	background-size: contain;
}


.logo-container {
	overflow: hidden;
}
