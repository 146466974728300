﻿$column-gap: rem(24px);

@import '~bulma/sass/grid/columns';
.columns {
	display: block;

	@include tablet {
		display: flex;
	}

	&.is-centered-till-tablet {
		display: flex;
		justify-content: center;

		@include tablet {
			justify-content: flex-start;
		}
	}

	&.has-gap-large {
		margin: rem(-24px);

		@include tablet {
			margin: rem(-38px);
		}

		@include desktop {
			margin: rem(-50px);
		}

		.column {
			padding: rem(24px);

			@include tablet {
				padding: rem(38px);
			}

			@include desktop {
				padding: rem(50px);
			}
		}
	}
}

.column {

	&.is-pulled-right {
		float: unset !important;

		@include desktop {
			order: 2;
		}
	}

	&.has-content-valign {
		display: flex;
		align-items: center;
	}
}