﻿.language {
	@include font-title-18;
	position: absolute;
	top: rem(31px);
	right: rem(20px);
	color: #fff;
	text-align: right;

	@include fullhd {
		right: rem(-10px);
	}

	&.is-open,
	&:hover {
		background: lighten($primary, 10%);

		.language-dropdown {
			display: block;
			background: lighten($primary, 10%);
		}
	}

	> .language-link {
		position: relative;
		display: block;
		width: rem(72px);
		height: rem(43px);
		color: #fff;

		&:before {
			@include icon($icon-globe);
			position: absolute;
			top: rem(10px);
			left: rem(20px);
			font-size: rem(22px);
		}

		&:after {
			@include icon($icon-arrow-bold-down);
			position: absolute;
			top: rem(17px);
			right: rem(10px);
			font-size: rem(9px);
		}
	}

	.language-dropdown {
		position: absolute;
		display: none;
		//width: rem(200px);
		padding: rem(8px) 0;
		top: 100%;
		right: 0;

		> .language-item {
			display: block;
			width: 100%;
			color: #fff;
			padding: rem(5px) rem(20px);

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
