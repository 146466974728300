@import 'shared';
@import 'fileupload';
@import 'input-textarea';
//@import 'checkbox-radio';
@import 'select';
//@import '~bulma/sass/form/file';
//@import '~bulma/sass/form/tools';

.field {
	margin: 0 0 2.5rem;

	&:not(:last-child) {
		margin: 0 0 2.5rem;
	}
}