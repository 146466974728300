$icon-arrow-bold-down: '\ea01';
$icon-arrow-bold-left: '\ea02';
$icon-arrow-bold-right: '\ea03';
$icon-arrow-bold-up: '\ea04';
$icon-arrow-scroll-down: '\ea05';
$icon-chevron-right: '\ea06';
$icon-close: '\ea07';
$icon-globe: '\ea08';
$icon-location: '\ea09';
$icon-play: '\ea0a';
$icon-upload: '\ea0b';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-arrow-scroll-down:before { content: $icon-arrow-scroll-down; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-close:before { content: $icon-close; }
.icon-globe:before { content: $icon-globe; }
.icon-location:before { content: $icon-location; }
.icon-play:before { content: $icon-play; }
.icon-upload:before { content: $icon-upload; }
