﻿.image-overview-filter
{
	display: flex;
	max-width: rem(468px);
	margin: 0 auto;

	.select {
		margin-right: rem(20px);
	}

	button {
		background: #fff;
		width: rem(48px);
		height: rem(48px);
		border: none;
		border-radius: 50%;
		margin: 0;
		flex-shrink: 0;

		&[type=submit] {

			.js & {
				display: none;
			}
		}
	}
}

.filter-reset {
	position: relative;
	text-indent: -9999px;
	overflow: hidden;
	color: $secondary;
	cursor: pointer;

	&:after {
		@include icon($icon-close);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(15px);
		text-indent: 0;
	}

	&:hover {
		color: #fff;
		background: $secondary;
	}
}