﻿$breadcrumb-item-color: $craft-text;
$breadcrumb-item-active-color: $craft-text;

$breadcrumb-item-padding-vertical: 0.75em;
$breadcrumb-item-padding-horizontal: 0.5em;

$breadcrumb-item-separator-color: $breadcrumb-item-color;

@import '~bulma/sass/components/breadcrumb';

.breadcrumb {
	@include font-title-16;
	background: rgba(#000, 0.1);

	&:not(:last-child) {
		margin-bottom: rem(85px);
	}

	&.has-succeeds-separator li + li {
		margin-left: 0;

		&:before {
			@include icon($icon-arrow-bold-right);
			position: relative;
			top: 1px;
			left: 0;
			font-size: rem(8px);
		}
	}

	a:hover {
		text-decoration: underline;
	}
}