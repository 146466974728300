﻿$button-color: $primary;
$button-background-color: $secondary;

$button-border-color: $white;
$button-border-width: 0;

//$button-padding-vertical: $control-padding-vertical;
//$button-padding-horizontal: $control-padding-horizontal;

$button-hover-color: mix($primary, $white, 20%);
$button-hover-border-color: mix($primary, $white, 20%);

$button-focus-color: $button-hover-color;
$button-focus-border-color: $button-hover-border-color;

$button-active-color: $button-focus-color;
$button-active-border-color: $button-hover-border-color;

@import '~bulma/sass/elements/button';

.button {
	@include font-title-20;
	position: relative;
	z-index: 0;
	height: auto;
	padding: rem(8px) rem(30px) rem(10px);
	border: none;
	border-radius: $radius;
	transition: all $speed ease-in-out;
	white-space: initial;
	overflow: hidden;
	text-align: left;
	color: #fff;
	text-decoration: none !important;
	transition: all 0.3s ease-in-out;

	@include desktop {
		white-space: nowrap;
	}

	&:hover {
		@extend .button-hover;
	}

	&.is-primary {

		&:hover {
			background: mix($secondary, #000, 90%);
		}
	}
}

.button-hover {
	color: #fff;
	background: mix($primary, #000, 90%);
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
	margin-right: 1.25rem;
}

