﻿.fileupload {

	input + label {
		display: none;
	}

	.js & {

		input {
			width: 0.1px !important;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		input + label {
			@include font-title-18;
			position: relative;
			display: block;
			padding: rem(12px) rem(18px);
			margin: 0;
			overflow: visible;
			background: $secondary;
			border-radius: $radius;
			cursor: pointer;

			&:after {
				@include icon($icon-upload);
				position: absolute;
				font-size: rem(23px);
				margin: rem(-10px) 0 0 0;
				top: 50%;
				right: rem(27px)
			}
		}
	}
}
