.header {
	position: relative;
	z-index: 3;
	min-height: rem(160px);
	background: $primary;

	@include tablet {
		min-height: rem(100px);
	}
}

.header-image {
	position: relative;
	height: rem(304px);

	@include desktop {
		height: rem(404px);
	}

	img {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}
}