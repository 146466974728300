﻿.overview-blocks {
	   
}

.overview-blocks-item {
	position: relative;
	display: block;

	.title {
		@include font-title-56;
	}

	&:hover {

		.button {
			@extend .button-hover;
		}
	}
}

.overview-blocks-item-header {
	position: absolute;
	z-index: 1;
	width: 100%;
	padding: 0 rem(20px);
	//max-width: rem(304px);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
}