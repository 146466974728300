﻿@import "~swiper/dist/css/swiper.css";

.slide {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 0;
	height: 100vh;
	min-height: rem(600px);

	.image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.slide-container {
	max-width: rem(676px);
}

.slide-title {
	font-size: rem(40px);
	line-height: rem(40px);
	font-weight: bold;
	color: #fff;
	text-shadow: 0px 5px 11px rgba(0, 0, 0, 0.8);
	margin: 0 0 rem(29px);

	@include desktop {
		font-size: rem(56px);
		line-height: rem(56px);
	}
}

.swiper-pagination {
	bottom: rem(31px);
	left: 50%;
	transform: translateX(-50%);

	.swiper-pagination-bullet {
		background: #fff;
		width: rem(12px);
		height: rem(12px);
		opacity: 1;
		margin: 0 rem(5px);

		&.swiper-pagination-bullet-active {
			background: $primary;
		}
	}
}