$textarea-min-height: 8.625rem;

@import '~bulma/sass/form/input-textarea';

.textarea {
	@include font-title-18;
	min-height: rem(150px);
	color: $craft-text;
	padding-right: rem(30px);
	padding-left: rem(30px);

	.form-contactnl & {
		min-height: rem(250px);
	}
}