﻿@font-face {
	font-family: 'Sansita';
	src: url('../../fonts/Sansita-Regular.woff2') format('woff2'), url('../../fonts/Sansita-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Sansita';
	src: url('../../fonts/Sansita-Italic.woff2') format('woff2'), url('../../fonts/Sansita-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Sansita';
	src: url('../../fonts/Sansita-Bold.woff2') format('woff2'), url('../../fonts/Sansita-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url('../../fonts/RobotoCondensed-Regular.woff2') format('woff2'), url('../../fonts/RobotoCondensed-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url('../../fonts/RobotoCondensed-Light.woff2') format('woff2'), url('../../fonts/RobotoCondensed-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'RobotoCondensed';
	src: url('../../fonts/RobotoCondensed-Bold.woff2') format('woff2'), url('../../fonts/RobotoCondensed-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@mixin font-text {
	font-family: $family-sans-serif;
	font-size: rem(22px);
	line-height: rem(32px);

	@media only screen and (min-width: 432px) {
		font-size: rem(21px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(20px);
	}
}

@mixin font-text-14 {
	font-size: rem(14px);
	line-height: 1.2em;
}

@mixin font-title-16 {
	font-family: $font-title;
	font-size: rem(16px);
	line-height: rem(24px);
	letter-spacing: 0.02em;
}

@mixin font-title-18 {
	font-family: $font-title;
	font-size: rem(18px);
	line-height: rem(24px);
	letter-spacing: 0.02em;
}

@mixin font-title-20 {
	font-family: $font-title;
	font-size: rem(20px);
	line-height: rem(24px);
	letter-spacing: 0.02em;
}

@mixin font-title-30 {
	font-family: $font-title;
	font-size: rem(30px);
	line-height: rem(40px);
}

@mixin font-title-40 {
	font-family: $font-title;
	font-size: rem(40px);
	line-height: 1.2em;
}

@mixin font-title-56 {
	font-family: $font-title;
	font-size: rem(36px);
	line-height: rem(36px);

	@include mobile-m {
		font-size: rem(46px);
		line-height: rem(46px);
	}

	@include tablet {
		font-size: rem(56px);
		line-height: rem(56px);
	}
}

@mixin font-title-64 {
	font-family: $font-title;
	font-size: rem(44px);
	line-height: 1.2em;

	@include mobile-m {
		font-size: rem(54px);
	}

	@include tablet {
		font-size: rem(64px);
	}
}