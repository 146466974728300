﻿$title-color: $text;
$title-weight: 700;

@import '~bulma/sass/elements/title';

.title {
	position: relative;
	white-space: normal;

	&.is-1 {
		@include font-title-40;
		text-align: center;
		color: $primary;
	}

	&.is-2 {
		@include font-title-40;
		margin: 0 0 rem(20px);
		color: $primary;
	}

	&.is-3 {
		@include font-title-30;
		margin: 0 0 rem(20px);
		color: $primary;
	}

	&.is-white {
		color: #fff;
	}

	&.has-shadow {
		text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
	}
}