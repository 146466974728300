﻿$card-shadow: none;

$card-header-background-color: $primary;

@import '~bulma/sass/components/card';

.card {

}

.card-header {
	@include font-title-20;
	display: block;
	background: #fff;
	text-align: center;
	padding: rem(27px) rem(20px) rem(36px);
	box-shadow: none;

	.card-title {
		@include font-title-30;
	}

	.title {

		&:not(.is-spaced) + .subtitle {
			margin-top: rem(-22px);
		}
	}

	.subtitle {
		@include font-title-20;
		color: $primary-light;
	}

	a,
	span {
		display: block;
	}

	a {
		color: $secondary;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}