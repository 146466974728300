﻿@import '~bulma/sass/elements/image';

.image {

	&.has-margin {
		margin-bottom: rem(34px);
	}

	&.has-placeholder {
		background: $primary url(../../../images/patern.svg);
		background-size: rem(500px) rem(500px);
	}


	&.is-product {
		@include responsive-container-psudo(428, 640);

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
			background: #fff;
		}
	}

	&.is-fill-container {
		position: relative;
		width: 100%;
		height: 100%;

		&.is-half {
			float: left;
			width: 50%;

			img {
				transition: top 1s ease-in-out;
			}
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&.is-video {
		position: relative;
		height: rem(230px);
		margin: 0 0 rem(9px);

		@include mobile-m {
			height: rem(330px);
		}

		@include tablet {
			height: rem(430px);
		}

		&:after {
			@include icon($icon-play);
			position: absolute;
			width: rem(80px);
			height: rem(80px);
			padding: rem(28px) 0 0 rem(5px);
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
			background: $secondary;
			background-size: cover;
			transition: all 0.25s ease-in-out;
			font-size: rem(26px);
			color: #fff;
			text-align: center;

			@include tablet {
				width: rem(90px);
				height: rem(90px);
				padding-top: rem(30px);
				font-size: rem(31px);
			}

			@include desktop {
				width: rem(100px);
				height: rem(100px);
				padding-top: rem(35px);
			}
		}

		&:hover {

			&:after {
				background: $primary;
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}