﻿.pagination-container {
	display: flex;
	width: 100%;
	align-content: center;

	.pagination {
		margin: 0 auto;
	}

	a {
		@extend .button;
		margin: rem(31px) 0 rem(9px);
	}
}
