﻿.google-maps-wrapper {
	position: relative;
}

.google-maps {
	height: rem(440px);

	@include tablet {
		height: rem(540px);
	}

	@include desktop {
		height: rem(640px);
	}
}

.route-form {
	z-index: 1;
	width: 100%;
	max-width: rem(856px);
	padding: rem(40px) rem(20px) rem(20px);
	background: $primary;

	@include tablet {
		position: absolute;
		display: flex;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background: none;
	}

	input,
	.select {
		margin: 0 0 rem(20px);

		@include tablet {
			flex-grow: 1;
			margin: 0 20px 0 0;
		}
	}

	button {
		margin: 0 0 rem(20px);

		@include tablet {
			margin: 0;
			flex-shrink: 0;
		}
	}
}