﻿$primary: #319c8a;
$primary-light: #83c4b9;
$primary-dark: #1b5249;
$primary-background: #eaf5f3;

$secondary: #ac145a;

$craft-background: #e4bc8a;
$craft-text: #53412b;

// Invert colors
$primary-invert: findColorInvert($primary);

// General colors
$background: #fff;

$border: rgba(#fff, 0.2);
//$border-hover: $grey-light !default
//$border-light: $grey-lightest !default
//$border-light-hover: $grey-light !default

// Text colors
$text-strong: inherit;
$text: $primary-dark;

// Link colors
$link: $primary;
$link-visited: $link;

$link-hover: $primary;
$link-hover-border: inherit;

$link-focus: inherit;
$link-focus-border: inherit;

$link-active: inherit;
$link-active-border: inherit;

// Typography

@import '~bulma/sass/utilities/derived-variables';