$footer-background-color: $secondary;
$footer-color: #fff;
$footer-padding: rem(67px) 0 0;

@import '~bulma/sass/layout/footer';

.footer {
	position: relative;
	z-index: 0;
}

.footer-block {
	@include font-title-20;
	width: 100%;
	margin: 0 0 rem(65px);

	h2 {
		@include font-title-30;
		margin: 0 0 rem(28px);
	}

	a {
		color: #fff;
	}

	.content:not(:last-child) {
		margin-bottom: rem(36px);
	}
}