﻿@import '~bulma/sass/elements/content';

.content {
	font-weight: 300;

	p {

		&:first-child {
			@include font-title-20;
			font-style: italic;
			line-height: rem(32px);
		}
	}

	p,
	dl,
	ol,
	ul,
	blockquote,
	pre,
	table {

		&:not(:last-child) {
			margin-bottom: rem(31px);
		}
	}

	+ .button {
		margin-bottom: rem(9px);
	}

	/*&:not(:last-child) {
		margin-bottom: rem(30px);
	}

	&:last-child {
		margin-bottom: rem(10px);
	}*/

	.subtitle {
		@include font-title-20;
		color: $primary;
		margin: 0;

		&:not(:first-child) {
			margin-top: rem(35px);
		}

		&:not(:last-child) {
			margin-bottom: rem(4px);
		}
	}

	ul {
		margin: 0;
		padding: 0 0 0 rem(20px);
		list-style: none;

		li {
			position: relative;
			margin: 0 0 rem(11px);

			&:before {
				@include icon($icon-chevron-right);
				position: absolute;
				top: rem(9px);
				left: rem(-20px);
				font-size: rem(10px);
				color: mix($secondary, #fff, 60%);
			}

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}