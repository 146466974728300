﻿.slideshow {
	position: relative;

	.swiper-pagination {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.slideshow-slide {
	overflow: hidden;
	min-height: rem(540px);
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100 - 13.5rem); // 216px

	@include tablet {
		height: calc(var(--vh, 1vh) * 100 - 9.75rem); // 156px
	}

	@include desktop {
		height: calc(var(--vh, 1vh) * 100 - 6.25rem); // 100px
	}
}

.slideshow-slide-content {
	position: absolute;
	z-index: 1;
	width: 100%;
	max-width: rem(600px);
	padding: rem(52px) rem(20px);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	transition: opacity 1s ease-in-out;

	.title {
		@include font-title-64;
		color: #fff;
		text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
		padding: 0 rem(20px);
		margin: 0 0 rem(30px);
	}
}

.slideshow-images {
	height: 100%;
}

.slideshow-scroll-down {
	position: absolute;
	z-index: 101;
	display: block;
	width: rem(48px);
	height: rem(48px);
	border-radius: 50%;
	bottom: rem(40px);
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	text-indent: -9999px;
	overflow: hidden;
	animation: MoveUpDown 3s linear infinite;

	&:after {
		@include icon($icon-arrow-scroll-down);
		position: absolute;
		padding: rem(2px) 0 0;
		top: 50%;
		left: 50%;
		font-size: rem(22px);
		transform: translate(-50%, -50%);
		text-indent: 0;
		color: $secondary;
	}
}

@keyframes MoveUpDown {
	0%, 100% {
		bottom: rem(40px);
	}

	50% {
		bottom: rem(80px);
	}
}