﻿$modal-background-background-color: rgba($primary, 0.8);

$modal-card-head-radius: 0;

@import '~bulma/sass/components/modal';

.modal {
	cursor: initial;
}

.modal.is-active {
	display: block;
}

.modal-card {
	overflow: hidden;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100% !important;
	max-width: rem(1272px);
	padding: 0 rem(20px);
	margin: 0;
	top: 50%;
	transform: translateY(-50%);
	overflow: auto;

	@include tablet {
		flex-wrap: nowrap;
	}
}

.modal-card-body {
	position: relative;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	@include tablet {
		flex-wrap: nowrap;
	}

	.content {
		padding: rem(31px) rem(20px) rem(36px);

		@include tablet {
			padding: rem(44px) rem(48px) rem(29px);
		}
	}

	button {
		position: absolute;
		width: rem(32px);
		height: rem(32px);
		border: 0;
		top: rem(10px);
		right: rem(10px);
		text-indent: -9999px;
		background: #fff;
		color: $primary;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		border-radius: 50%;
		color: $secondary;

		&:after {
			@include icon($icon-close);
			position: absolute;
			top: 50%;
			left: 50%;
			text-indent: 0;
			font-size: rem(12px);
			transform: translate(-50%, -50%);
		}

		&:hover {
			background: $secondary;
			color: #fff;
		}
	}

	table {

		tr {

			&:nth-child(odd) td {
				background: mix($primary, #fff, 10%);
			}
		}

		td,
		th {
			display: block;
			padding: rem(10px) rem(20px) rem(5px) rem(20px);

			@include mobile-m {
				display: table-cell;
			}

			&:first-child {
				@include font-title-20;

				@include mobile-m {
					width: rem(196px);
					padding: rem(10px) 0 rem(5px) rem(20px);
				}
			}
		}
	}
}

.modal-slidshow {
	width: 100%;

	@include tablet {
		float: left;
		max-width: rem(264px);
		order: -1;
	}

	@include desktop {
		width: 100%;
		max-width: rem(464px);
	}

	.swiper-container {
		height: 100%;
	}
}