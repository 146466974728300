@import '~bulma/sass/components/level';

.level {

	&.is-legal-bar {
		@include font-text-14;
		color: #fff;
		font-weight: 300;
		padding: rem(17px) 0 rem(80px);
		background: mix($secondary, #fff, 90%);

		@include desktop {
			padding: rem(17px) 0 rem(12px);
		}

		a {
			color: #fff;

			&:hover {
				text-decoration: underline;
			}
		}

		.level-item {
			margin-right: 1.5625rem;
			justify-content: flex-start;
		}
	}
}