﻿.highlighted-text-block {
	background: $primary;
	color: #fff;
	overflow: hidden;

	.container {

		@include desktop {
			margin: 0 0 0 auto;
		}
	}

	.highlighted-text-block-text {
		padding: rem(80px) 0 rem(91px);
	}
}
