﻿.navbar.responsive {
	position: fixed;
	z-index: 1000;
	width: rem(400px);
	height: 100vh;
	top: 0;
	left: rem(-400px);
	//left: 0;
	background: #fff;
	opacity: 0;
	//opacity: 1;
	transition: all 0.25s ease-in-out;
	background: $secondary;

	&.is-open {
		left: 0;
		opacity: 1;
	}

	.navbar-menu {
		padding: rem(100px) 0 rem(80px);
	}

	.navbar-item {
		padding: 0;

		&:first-child .navbar-link {
			border-top: 1px solid mix($secondary, #fff, 80%);
		}
	}

	.has-dropdown {

		> .navbar-link {
			position: relative;

			&:after {
				@include icon($icon-arrow-bold-down);
				position: absolute;
				top: rem(25px);
				right: rem(23px);
				font-size: rem(10px);
			}

			&:before {
				position: absolute;
				content: '';
				width: 1px;
				height: 100%;
				top: 0;
				right: rem(56px);
				background: mix($secondary, #fff, 80%);
			}
		}

		&:hover,
		&.is-open {
			background: mix($secondary, #fff, 90%);
		}

		&.is-open {

			.navbar-dropdown {
				display: block;
			}

			> .navbar-link {

				&:after {
					@include icon($icon-arrow-bold-up);
				}
			}
		}
	}

	.navbar-link {
		@include font-title-18;
		display: block;
		width: 100%;
		padding: rem(14px) rem(40px) rem(15px);
		border-bottom: 1px solid mix($secondary, #fff, 80%);
		font-size: rem(18px);
		transition: all 0.25s ease-in-out;
		color: #fff;

		&:hover {
		}

		&:hover,
		&.is-active {
			background: mix($secondary, #fff, 80%);
		}
	}

	.navbar-dropdown {
		display: none;

		.navbar-item:first-child .navbar-link {
			border-top: none;
		}

		.navbar-link {
			background: mix($secondary, #fff, 90%);

			&:hover,
			&.is-active {
				background: mix($secondary, #fff, 80%);
			}
		}

		.navbar-item-text {
			position: relative;
			padding: 0 0 0 rem(20px);

			&:before {
				@include icon($icon-chevron-right);
				position: absolute;
				top: rem(7px);
				left: 0;
				font-size: rem(10px);
				color: mix($secondary, #fff, 60%);
			}
		}
	}
}
