﻿$mobile: 432px !default;
$mobile-l: 640px !default;

@mixin desktop-and-hover {
	@media screen and (min-width: $desktop) and (hover: hover) {

		body:not(.headroom--not-top) & {
			@content;
		}
	}
}

@mixin tablet-and-widescreen {
	@media screen and (min-width: $widescreen) and (hover: hover) {

		body:not(.headroom--not-top) & {
			@content;
		}
	}
}

@mixin mobile-m {
	@media screen and (min-width: $mobile), print {
		@content;
	}
}

@mixin mobile-l {
	@media screen and (min-width: $mobile-l), print {
		@content;
	}
}