﻿.product {
	position: relative;
	margin: 0 0 rem(125px);

	.js & {
		cursor: pointer;
	}

	&:hover {

		.product-image img {
			transform: scale(1.2);
		}
	}
}

.product-header {
	position: absolute;
	z-index: 1;
	width: calc(100% - 2.5rem); // 40px
	padding: rem(8px) rem(40px) rem(19px);
	top: rem(20px);
	left: rem(20px);
	background: #fff;
	text-align: center;
}

.product-title {
	@include font-title-30;
	line-height: rem(30px);
	color: $primary;
	font-weight: bold;
}

.product-subtitle {
	@include font-title-20;
	display: block;
	color: $primary-light;
	margin: 0 0 rem(-3px);
}

.product-image {
	position: relative;
	overflow: hidden;

	img {
		transform: scale(1);
		transition: all 0.25s ease-in-out;
	}
}

.product-flower-image {
	position: absolute;
	width: rem(250px);
	height: rem(250px);
	bottom: rem(-125px);
	left: 50%;
	transform: translateX(-50%);
}

.product-new {
	position: absolute;
	z-index: 1;
	display: block;
	width: rem(80px);
	height: rem(80px);
	top: rem(20px);
	left: rem(20px);
	background: url(../../images/new.png) no-repeat;
	background-size: contain;
	overflow: hidden;
	text-indent: -9999px;

	&.is-in-slider {
		top: rem(40px);
		left: rem(40px);
	}
}