﻿.call-to-action {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 0;
	height: rem(400px);
	text-align: center;

	@include mobile {
		height: rem(500px);
	}

	@include tablet {
		height: rem(600px);
	}

	h2 {
		@include font-title-64;
		color: #fff;
		text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
		margin: 0 0 rem(28px);
		font-weight: bold;
	}

	img {
		position: absolute;
		z-index: -1;
		width: auto;
		max-width: initial;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.call-to-action-content {
	padding: rem(50px) 0 rem(50px) 0;
}