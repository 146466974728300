﻿
$table-color: $text;
$table-background-color: transparent;

$table-cell-border: 1px solid #c2c2c2;
$table-cell-border-width: 0 0 1px;
$table-cell-padding: 0.125rem 0.625rem 0.375rem;
$table-cell-heading-color: $primary;

//$table-head-cell-border-width: 0 0 2px !default
$table-head-cell-color: $primary;
//$table-foot-cell-border-width: 2px 0 0 !default
//$table-foot-cell-color: $text-strong !default

//$table-head-background-color: transparent !default
//$table-body-background-color: transparent !default
//$table-foot-background-color: transparent !default

//$table-row-hover-background-color: $scheme-main-bis !default

//$table-row-active-background-color: $primary !default
//$table-row-active-color: $primary-invert !default

//$table-striped-row-even-background-color: $scheme-main-bis !default
//$table-striped-row-even-hover-background-color: $scheme-main-ter !default*/

@import '~bulma/sass/elements/table';

.table {

	th,
	td {
		@include font-text;
		font-weight: 300;
		color: $text;
	}
}

.table.shows-information {

	td,
	th {
		@include font-text;
		display: block;
		border: 0;
		padding: 0 0 rem(32px);
		color: $craft-text;

		@include mobile-l {
			display: table-cell;
			width: 50%;
			padding: 0;

			&:nth-child(2) {
				padding: 0 0 0 rem(24px);
			}
		}

		a {
			position: relative;
			color: $craft-text;
			text-decoration: none;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				bottom: 0;
				left: 0;
				background: $craft-text;
			}

			&:hover:before {
				display: none;
			}
		}

		p {
			margin: 0;
		}

		strong {
			@include font-title-20;
			font-style: italic;
			font-weight: normal;
		}
	}
}