$input-border-color: #fff;
$input-height: rem(48px);
$input-shadow: none;
$input-placeholder-color: #fff;

$input-hover-border-color: $primary;

$input-focus-color: $text;
$input-focus-border-color: $secondary;
$input-focus-box-shadow-size: none;
$input-focus-box-shadow-color: none;

$input-radius: $radius;

@import '~bulma/sass/form/shared';


.input {
	@include font-title-18;
	font-weight: normal;
	height: $input-height;
	width: 100%;
	border-radius: $radius;
	border: 1px solid #fff;
	padding: rem(10px) rem(20px);
	color: $craft-text;

	&[name="email2"] {
		display: none;
	}
}

.select,
.select select {
	width: 100%;
}

.control .select {
	border-radius: $radius;

	select {
		font-size: 1rem;
	}
}

.input::placeholder, .select select::placeholder, .textarea::placeholder {
	opacity: 1;
	color: $craft-text;
}

form {

	.label {
		@include font-title-18;
		display: block;
		color: inherit;
	}

	.field, .field:not(:last-child) {
		margin: 0 0 rem(14px);
	}

	.label:not(:last-child) {
		margin: 0 0 rem(16px);
	}

	button {
		margin-top: rem(40px);
	}
}