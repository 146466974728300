@import '~bulma/sass/form/select';

.select {
	@include font-title-18;
	color: $craft-text;

	select {
		@include font-title-18;
		height: 100%;
		padding: 0 rem(30px);
		border-radius: $radius;

		/*&:active,
		&:focus {
			border-radius: $radius $radius 0 0;
		}*/
	}

	&:not(.is-multiple):not(.is-loading)::after {
		@include icon($icon-arrow-bold-down);
		font-size: rem(10px);
		border: none;
		margin: 0;
		top: rem(20px);
		right: rem(30px);
		transform: rotate(0);
		color: $secondary;
	}
}